import React from "react";
import { UploadIssues } from "../../../../sharedTypes/bucketUploadTypes";
import { getSupportEmail } from "../../lib/getSupportEmail";
import { StudyMetadata } from "../../providers/FileStateProvider";
import { downloadUploadIssuesCSV } from "../../lib/downloadUploadIssuesCSV";
import "./UploadIssuesReport.css";
import MailTo from "./MailTo";

type UploadIssuesReportProps = {
    uploadIssues: UploadIssues;
    studyMetadata: StudyMetadata[];
};

type UploadIssuesReportPropsWithEmail = UploadIssuesReportProps & {
    helpDeskEmailAddress?: string;
};

type UploadCounts = {
    totalUploadCount: number;
    successfulUploadCount: number;
    alreadyPresentInstancesCount: number;
    failedInstancesCount: number;
};

const getUploadCounts = (uploadIssues: UploadIssues, studyMetadata: StudyMetadata[]): UploadCounts => {
    let totalUploadCount = 0;

    studyMetadata.forEach(study => {
        totalUploadCount += study.fileCount;
    });

    let issueCount = 0;
    let alreadyPresentInstancesCount = 0;
    let failedInstancesCount = 0;

    Object.keys(uploadIssues.studies).forEach(StudyInstanceUID => {
        const study = uploadIssues.studies[StudyInstanceUID];

        issueCount += study.totalCount;

        Object.keys(study.series).forEach(SeriesInstanceUID => {
            const series = study.series[SeriesInstanceUID];

            alreadyPresentInstancesCount += series.alreadyPresentInstances.length;
            failedInstancesCount += series.failedInstances.length;
        })
    })

    const successfulUploadCount = totalUploadCount - issueCount;

    return {
        totalUploadCount,
        successfulUploadCount,
        alreadyPresentInstancesCount,
        failedInstancesCount
    };
}



const UploadIssuesReportHeader = ({
    totalUploadCount,
    successfulUploadCount,
    alreadyPresentInstancesCount,
    failedInstancesCount
}: UploadCounts) => {

    return (
        <table className="upload-issues-report-table upload-issues-report-header-table">
            <tbody>
                <tr>
                    <th>Total Uploaded Instances</th>
                    <th>Succeeded</th>
                    {alreadyPresentInstancesCount > 0 ? <th>Already In Yunu</th> : null}
                    {failedInstancesCount > 0 ? <th>Failed</th> : null}
                </tr>
                <tr>
                    <td>{totalUploadCount}</td>
                    <td>{successfulUploadCount}</td>
                    {alreadyPresentInstancesCount > 0 ? <td>{alreadyPresentInstancesCount}</td> : null}
                    {failedInstancesCount > 0 ? <td>{failedInstancesCount}</td> : null}
                </tr>
            </tbody>
        </table>
    );
}

const UploadIssuesTable = ({
    studyMetadata,
    uploadIssues,
    title,
    queryField
}: {
    uploadIssues: UploadIssues;
    studyMetadata: StudyMetadata[];
    title: JSX.Element;
    queryField: "alreadyPresentInstances" | "failedInstances"
}) => {
    const rowData: {
        StudyInstanceUID: string;
        StudyDescription: string;
        SeriesInstanceUID: string;
        count: number;
    }[] = []

    Object.keys(uploadIssues.studies).forEach(StudyInstanceUID => {
        const studyIssues = uploadIssues.studies[StudyInstanceUID];
        let StudyDescription = "";

        const study = studyMetadata.find(s => s.StudyInstanceUID === StudyInstanceUID);

        if (study) {
            StudyDescription = study.header.StudyDescription;
        }

        Object.keys(studyIssues.series).forEach(SeriesInstanceUID => {
            const series = studyIssues.series[SeriesInstanceUID];

            const count = series[queryField].length;

            if (count > 0) {
                rowData.push({
                    StudyInstanceUID,
                    StudyDescription,
                    SeriesInstanceUID,
                    count
                })
            }
        });
    });

    const rows = rowData.map(row => {
        return (
            <tr key={`${row.StudyInstanceUID}-${row.SeriesInstanceUID}`}>
                <td>{`${row.StudyDescription} (${row.StudyInstanceUID})`}</td>
                <td>{row.SeriesInstanceUID}</td>
                <td>{row.count}</td>
            </tr>
        );
    });

    return (
        <div>
            {title}
            <table className="upload-issues-report-table upload-issues-report-issues-table">
                <tbody>
                    <tr>
                        <th>Study</th>
                        <th>Series</th>
                        <th className="upload-issues-report-issues-table-count">Instances</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        </div>
    );
};

const UploadIssuesReport = ({ uploadIssues, helpDeskEmailAddress, studyMetadata }: UploadIssuesReportPropsWithEmail) => {
    const {
        totalUploadCount,
        successfulUploadCount,
        alreadyPresentInstancesCount,
        failedInstancesCount
    } = getUploadCounts(uploadIssues, studyMetadata);
    const supportEmail = getSupportEmail(helpDeskEmailAddress);

    const failedInstancesTitle = (
        <h4>
            Some instances failed to upload. There may be an issue with these files, please contact <MailTo supportEmail={supportEmail} />:
        </h4>
    );

    const alreadyPresentInstancesTitle = (
        <h4>
            Some instances were already present and have not been modified:
        </h4>
    );

    return (
        <div className="upload-issues-report">
            <h2>Upload Report</h2>
            <div className="upload-issues-report-copy-to-clipboard-container">
                <div>
                    <button onClick={() => downloadUploadIssuesCSV(uploadIssues)} className='upload-issues-report-csv-button'>Download CSV Upload Report</button>
                </div>
            </div>
            <UploadIssuesReportHeader
                totalUploadCount={totalUploadCount}
                successfulUploadCount={successfulUploadCount}
                alreadyPresentInstancesCount={alreadyPresentInstancesCount}
                failedInstancesCount={failedInstancesCount}
            />
            {alreadyPresentInstancesCount > 0
                ? <UploadIssuesTable
                    uploadIssues={uploadIssues}
                    studyMetadata={studyMetadata}
                    queryField="alreadyPresentInstances"
                    title={alreadyPresentInstancesTitle}
                />
                : null
            }
            {failedInstancesCount > 0
                ? <UploadIssuesTable
                    uploadIssues={uploadIssues}
                    studyMetadata={studyMetadata}
                    queryField="failedInstances"
                    title={failedInstancesTitle}
                />
                : null
            }
        </div>
    );
};

export default UploadIssuesReport;