import React from "react";
import { useFileState } from "../../providers/FileStateProvider";
import "../NextPrevButton.css"
import LoadingSpinner from "../Shared/LoadingSpinner";


type UserInputFooterBlindedProps = {
    canUpload: boolean;
    defaultDeidentifiedSubjectId?: string;
    userDefinedDeidentifiedSubjectId?: string;
    selectedSiteId?: string;
}


function UserInputFooterBlinded({
    canUpload,
    defaultDeidentifiedSubjectId,
    userDefinedDeidentifiedSubjectId,
    selectedSiteId
}: UserInputFooterBlindedProps) {
    const {
        api,
        files,
        attachments,
        studyDateOverride,
        uploadInput,
        studyMetadata,
        guestSenderInfo,
        rewriteUIDs,
        status
    } = useFileState()

    const canUploadAndAnyCancelledUploadsFinished = canUpload && !status.isUploading;
    const uploadButtonClassName = canUploadAndAnyCancelledUploadsFinished ? "next-prev-button" : "next-prev-button next-prev-button_disabled";

    return (
        <div>
            <button className="next-prev-button" onClick={() => {
                api.resetState()
            }}>
                Back
            </button>
            <button className={uploadButtonClassName} disabled={!canUploadAndAnyCancelledUploadsFinished} onClick={() => {
                const subjectId = defaultDeidentifiedSubjectId !== undefined ? defaultDeidentifiedSubjectId : userDefinedDeidentifiedSubjectId;

                api.upload({
                    files,
                    attachments,
                    uploadInput,
                    selectedSiteId,
                    studyMetadata,
                    studyDateOverride,
                    subjectId,
                    guestSenderInfo,
                    rewriteUIDs
                });
            }}>
                Upload
            </button>
            {status.isUploading ? <div style={{ padding: '10px' }}><LoadingSpinner /></div> : null}
        </div>
    )
}

export default UserInputFooterBlinded