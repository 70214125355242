import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFileState } from '../providers/FileStateProvider';
import { StateCode } from '../providers/FileStateProvider';
import { GuestUploader as GuestUploaderType } from '../../../sharedTypes/bucketUploadTypes';
import GuestUploaderSelectData from './GuestUploader/GuestUploaderSelectData';
import UploadStatus from './Shared/UploadStatus';
import PageWrapper from './PageWrapper';
import {
  fetchGuestUploader,
  fetchTrialGuest,
  fetchPatientGuest,
  fetchOrderForTrialPatientGuest,
} from '../lib/api/wms';
import { DeidentificationProtocol } from '../../../sharedTypes/deidentifyTypes';
import { OrderResponse, SitePatientsForPatientResponse, TrialResponse } from '../../../sharedTypes/wmsQueryTypes';
import LoadingSpinner from './Shared/LoadingSpinner';
import UserInput from './UserInput/UserInput';
import { getSupportEmail } from '../lib/getSupportEmail';
import MailTo from './Shared/MailTo';

// Extend react type interfaces to allow folder selection.
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes for folder upload to work.
    directory?: string;
    webkitdirectory?: string;
  }
}

function GuestUploader() {
  const { id } = useParams();

  const {
    api,
    state,
    uploadInput,
  } = useFileState();

  const [urlSearchParams] = useSearchParams();

  const [guestUploader, setGuestUploader] = useState<GuestUploaderType | null>(
    null
  );
  const [InvalidComponent, setInvalidComponent] = useState<React.JSX.Element | undefined>();

  useEffect(() => {
    // Reset the state on page load
    api.resetState();

    const initialize = async (id: string): Promise<void> => {
      const result = await fetchGuestUploader(id);

      if (!result) {
        setInvalidComponent(<p>Invalid guest uploader.</p>);
        return;
      }

      const { guestUploader, helpDeskEmailAddress } = result

      if (guestUploader.disabled) {
        const supportEmail = getSupportEmail(helpDeskEmailAddress);

        // Note: We can safely just exit here. This guest uploader will be blocked by the backend if one tries to create
        // an instance manually via API anyway.
        setInvalidComponent((
          <p>
            Uploader is not active. Please contact <MailTo supportEmail={supportEmail} /> for assistance.
          </p >
        ));

        return;
      }

      const { id: guestUploaderId, temporary } = guestUploader;

      /**
       * Note: We do this here to prevent confusion between null and undefined later.
       * DB entries come back as null if not populated.
       */
      const trial_id: string | undefined = guestUploader.trial_id || undefined;
      const trial_patient_id: string | undefined =
        guestUploader.trial_patient_id || undefined;
      const order_id: string | undefined = guestUploader.order_id || undefined;

      let trial: TrialResponse | undefined = undefined;
      let patient: SitePatientsForPatientResponse | undefined = undefined;
      let order: OrderResponse | undefined = undefined;

      if (
        trial_id !== undefined &&
        trial_patient_id !== undefined &&
        order_id !== undefined
      ) {
        order = await fetchOrderForTrialPatientGuest(
          trial_id,
          trial_patient_id,
          order_id,
          guestUploaderId
        );

        if (!order) {
          // Order does not exist for patient.
          setInvalidComponent(<p>Order for patient in trial does not exist.</p>);
          return;
        }
      }

      if (trial_id !== undefined) {
        trial = await fetchTrialGuest(trial_id, guestUploaderId);

        if (!trial) {
          setInvalidComponent(<p>Trial does not exist.</p>);
          return;
        }
      }

      if (trial_id !== undefined && trial_patient_id !== undefined) {
        patient = await fetchPatientGuest(trial_id, trial_patient_id, guestUploaderId);

        if (!patient) {
          setInvalidComponent(<p>Patient does not exist.</p>);
          return;
        }
      }

      /**
       * Custom deidentification protocol for the guest uploader.
       */
      const guestUploaderProtocol = guestUploader.default_tag_input?.protocol;

      const site_id = guestUploader.site_id !== null ? guestUploader.site_id : undefined

      let deidentifying: boolean;
      let trialAnonymizationProtocol: DeidentificationProtocol | undefined = undefined;
      let redactBurnedInPHI: boolean = false

      if (trial) {
        deidentifying = trial.anonymizationProtocol.deidentifying || !!guestUploaderProtocol;
        redactBurnedInPHI = trial.anonymizationProtocol.redactBurnedInPHI;
        trialAnonymizationProtocol = trial.anonymizationProtocol.deidentificationProtocol
      } else {
        deidentifying = !!guestUploaderProtocol;
      }

      // Set state
      api.setUploadInput({
        helpDeskEmailAddress,
        guestUploader: {
          guestUploaderId: guestUploader.id,
          temporary
        },
        trial_id,
        trial_patient_id,
        order_id,
        trial,
        patient,
        order,
        deidentifying,
      });

      if (site_id) {
        api.setSiteId(site_id)
      }

      api.setMandatoryDeidentifyList(
        trialAnonymizationProtocol,
        guestUploaderProtocol
      );
      api.setMandatoryRedactBurnedInPHI(redactBurnedInPHI);

      setGuestUploader(guestUploader);
    };

    if (id) {
      initialize(id);
    }

    // We really only want this to happen once.
    // eslint-disable-next-line
  }, [id, urlSearchParams]);

  if (InvalidComponent) {
    return (
      <PageWrapper>
        {InvalidComponent}
      </PageWrapper>
    );
  }

  if (guestUploader === null) {
    return (
      <PageWrapper>
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  const isNonTemporaryGuestUploader = uploadInput.guestUploader?.temporary !== undefined ? !uploadInput.guestUploader?.temporary : false;

  if (state === StateCode.SELECT_DATA) {
    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <GuestUploaderSelectData guestUploader={guestUploader} />
      </PageWrapper>
    );
  }

  if (state === StateCode.DEID_AND_ADD_ATTACHMENTS) {
    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <UserInput />
      </PageWrapper>
    );
  }

  if (state === StateCode.UPLOAD_STATUS) {
    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <UploadStatus />
      </PageWrapper>
    );
  }

  return <div className="guest-uploader" />;
}

export default GuestUploader;
