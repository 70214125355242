import { useEffect } from "react"
import axios from "axios"
import throttle from 'lodash.throttle'
import { isCurrentUploaderInstanceId } from "../../providers/utils/uploadCancelledState"

/**
 * Updates the backend periodically with the list of uploaded files.
 */
const throttledUploaderStatusUpload = throttle(async (uploaderInstanceId: string, completed: string[]) => {
  try {
    await axios.patch('/api/uploader/instance/update-status', { uploaderInstanceId, completed })
  } catch (e: any) {
    if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
      // Upload is in cancelled state, or new upload has been started and this is an old update handler.
      return;
    }

    throw new Error(e.message)
  }
}, 5000, { leading: true, trailing: true })

const postComplete = async (uploaderInstanceId: string): Promise<void> => {
  try {
    await axios.post('/api/uploader/instance/complete', { uploaderInstanceId })
  } catch (e: any) {
    if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
      // Upload is in cancelled state, or new upload has been started and this is an old complete handler.
      return;
    }

    throw new Error(e.message)
  }
}


const useOnUploadComplete = (uploaderInstanceId: string | undefined, completedToNotify: string[], uploadedFileCount: number, totalFileCount: number) => {
  useEffect(() => {
    if (!uploaderInstanceId || !completedToNotify.length || !totalFileCount || !isCurrentUploaderInstanceId(uploaderInstanceId)) {
      return
    }

    if (uploadedFileCount === totalFileCount) {
      postComplete(uploaderInstanceId)

      return
    }

    throttledUploaderStatusUpload(uploaderInstanceId, completedToNotify)
  }, [completedToNotify, uploaderInstanceId, totalFileCount, uploadedFileCount])
}


export { useOnUploadComplete }