import React, { useEffect, useState } from "react";
import { StudyMetadata, UploadInput, useFileState } from "../../providers/FileStateProvider";
import './UploadStatus.css'
import "../NextPrevButton.css"
import { LinearProgress } from '@mui/material'
import { UploaderInstanceStatusResponse, UploadIssues } from "../../../../sharedTypes/bucketUploadTypes";
import { UploadStatus as UploadStatusEnum } from "../../lib/consts";
import { auth0Axios } from "../../auth/auth0Axios";
import LoadingSpinner from "./LoadingSpinner";
import { getSupportEmail } from "../../lib/getSupportEmail";
import { getApproximateStepUploadTimeForStep, modifyProgressTickTime } from "../../lib/getApproximateStepUploadTimeForStep";
import UploadIssuesReport from "./UploadIssuesReport";
import MailTo from "./MailTo";

const ATTACHMENTS = 'ATTACHMENTS'
const WORKFLOW_STATUS_POLL_INTERVAL = 1000;

type UploadStatusMessageProps = {
    statusAndResults: UploaderInstanceStatusResponse;
    fileCount: number;
    uploadInput: UploadInput;
    studyMetadata: StudyMetadata[];
    helpDeskEmailAddress?: string;
};

const getFileCount = (studyMetadata: StudyMetadata[]): number => {
    let fileCount = 0;

    studyMetadata.forEach(study => {
        fileCount += study.fileCount
    });

    return fileCount;
};

type ApproximateProgressProps = {
    approximateStepTime: number;
    currentStep: number;
};

const ApproximateProgress = ({ approximateStepTime, currentStep }: ApproximateProgressProps) => {
    const [stepProgress, setStepProgress] = useState<number>(0);
    // const [timeOutId, setTimeOutId] = useState<number | undefined>(undefined);
    const progressTickTime = approximateStepTime / 100.0;


    useEffect(() => {
        setStepProgress(0);
    }, [currentStep, approximateStepTime]);


    useEffect(() => {
        const stepIncreaseTimeOut = () => {
            setStepProgress((oldValue) => {
                const newValue = oldValue + 1;

                return Math.min(oldValue + 1, 99);
            })
        };

        if (stepProgress < 99) {
            const modifiedProgressTickTime = modifyProgressTickTime(progressTickTime, stepProgress);

            setTimeout(stepIncreaseTimeOut, modifiedProgressTickTime) as unknown as number;
        }
    }, [stepProgress, approximateStepTime])


    return (
        <div style={{ width: '400px', margin: "auto" }}>
            <LinearProgress
                variant="determinate"
                value={stepProgress}
                sx={{
                    backgroundColor: 'var(--loading-white)',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'var(--loading-blue)'
                    }
                }}
            />
        </div>
    );
};

/**
 * Todo make this into a component instead
 */
const UploadStatusMessage = ({
    uploadInput,
    fileCount,
    statusAndResults,
    studyMetadata,
    helpDeskEmailAddress,
}: UploadStatusMessageProps) => {
    const { status, viewerUrls, orderUrl, uploadIssues } = statusAndResults

    const [statusMessage, setStatusMessage] = useState<string>("");
    const [failureMessage, setFailureMessage] = useState<string | undefined>("Uploaded images failed to process, please retry.");
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [approximateStepTime, setApproximateStepTime] = useState<number>(60);



    useEffect(() => {
        const { detail } = statusAndResults;

        if (detail === undefined) {
            return;
        }


        const { steps, failed, message } = detail;

        if (message === undefined) {
            return;
        }

        if (failed && message !== undefined) {
            setFailureMessage(message);

            return;
        }

        if (steps !== undefined && message !== undefined) {
            setStatusMessage(`Processing: ${steps.current}/${steps.total} ${message}`);

            if (steps.current !== currentStep) {
                // New step, reset loading bar.
                const approximateStepTime = getApproximateStepUploadTimeForStep(uploadInput, fileCount, steps.current);

                setCurrentStep(steps.current);
                setApproximateStepTime(approximateStepTime);
            }
        }

    }, [statusAndResults, currentStep])

    switch (status) {
        case UploadStatusEnum.PROCESSING:
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Upload to Yunu Complete</p>
                    <p className="upload-footer-header"> {statusMessage}</p>
                    {currentStep !== 0 ?
                        <ApproximateProgress
                            currentStep={currentStep}
                            approximateStepTime={approximateStepTime}
                        />
                        : null}
                    <p>You may safely close your browser window and processing will continue.</p>
                    <LoadingSpinner />
                </React.Fragment>
            );
        case UploadStatusEnum.COMPLETE:
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Uploads Complete</p>
                    {viewerUrls && orderUrl ?
                        <React.Fragment>
                            <p><a className="upload-footer-link" href={`${orderUrl}`}>Link to order in Yunu</a></p>
                            <p>{`View timepoints in Yunu Viewer:`}</p>
                            {viewerUrls.map(({ viewerUrl, trialResponseAssessmentCriteriaName }) => {
                                return <p key={viewerUrl}><a className="upload-footer-link" href={`${viewerUrl}`}>{trialResponseAssessmentCriteriaName}</a></p>
                            })}
                            {uploadIssues
                                ? <UploadIssuesReport
                                    uploadIssues={uploadIssues}
                                    helpDeskEmailAddress={helpDeskEmailAddress}
                                    studyMetadata={studyMetadata}
                                />
                                : null
                            }
                        </React.Fragment>
                        : null}
                </React.Fragment>
            );
        case UploadStatusEnum.FAILED:
            const supportEmail = getSupportEmail(helpDeskEmailAddress);
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Processing Failed</p>
                    <p>
                        {`${failureMessage} Please contact `} <MailTo supportEmail={supportEmail} />.
                    </p>
                </React.Fragment>
            );
    }

    return null;
};

const UploadStatus = () => {
    const {
        status,
        studyMetadata,
        attachments,
        files,
        failedUploads,
        api,
        uploaderInstanceId,
        uploadInput
    } = useFileState()
    const { isUploading } = status;

    const [statusAndResults, setStatusAndResults] = useState<UploaderInstanceStatusResponse>({ status: UploadStatusEnum.INITIALIZED, viewerUrls: [] })

    const fileCount = getFileCount(studyMetadata);

    useEffect(() => {
        if (isUploading) {
            return;
        }

        let keepPolling = true;

        const pollForStatus = async () => {
            const result = await auth0Axios.get(`/api/uploader/instance/upload-status/${uploaderInstanceId}`)

            const uploadStatus = result.data as UploaderInstanceStatusResponse;

            const { status } = uploadStatus

            // Changed, update state
            setStatusAndResults(uploadStatus)

            // Keep polling if not complete or failed:
            if (![UploadStatusEnum.COMPLETE, UploadStatusEnum.FAILED].includes(status) && keepPolling) {
                setTimeout(pollForStatus, WORKFLOW_STATUS_POLL_INTERVAL);
            }
        }


        if (uploaderInstanceId && ![UploadStatusEnum.COMPLETE, UploadStatusEnum.FAILED].includes(statusAndResults.status)) {
            // Fetch API and update statusAndResults
            pollForStatus()
        }

        return () => {
            /**
             * If we go to upload more, stop polling this endpoint.
             */
            keepPolling = false;
        }
    }, [uploaderInstanceId, isUploading])

    const dicomStatusLines = studyMetadata.map(study => {
        const { StudyInstanceUID } = study
        const uploadCount = status.fileGroupUploadCounts[StudyInstanceUID] || 0
        const fileCount = files[StudyInstanceUID].length

        let progress: number

        const complete = uploadCount === fileCount

        if (uploadCount === 0) {
            progress = 0
        } else if (uploadCount / fileCount < 0.01) {
            // For better UX, if we have a single upload completed, show 1% progress
            // Even if not factually accurate.
            progress = 1
        } else if (complete) {
            progress = 100
        } else {
            progress = Math.floor(100 * (uploadCount / fileCount))
        }

        return (
            <div className="upload-status-item" key={StudyInstanceUID}>
                <p className="upload-status-name">{study.header.StudyDescription}</p>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        backgroundColor: 'var(--loading-white)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'var(--loading-blue)'
                        }
                    }}
                />
                <div className="upload-status-progres-container">
                    <p className="upload-status-progress">{`${progress}% Complete`}</p>
                    <div style={{ width: '12px', height: '12px' }}>
                        {complete ? null : <LoadingSpinner />}
                    </div>
                </div>
            </div>
        )
    })

    const attachmentStatus = () => {
        if (attachments.length === 0) {
            return null
        }

        const uploadCount = status.fileGroupUploadCounts[ATTACHMENTS] || 0
        const fileCount = attachments.length

        let progress: number

        if (uploadCount === fileCount) {
            progress = 100
        } else {
            progress = Math.floor(uploadCount / fileCount)
        }

        return (
            <div className="upload-status-item">
                <p className="upload-status-name">Attachments</p>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        backgroundColor: 'var(--loading-white)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'var(--loading-blue)'
                        }
                    }}
                />
                <p className="upload-status-progress">{`${progress}% Complete`}</p>
            </div>
        )
    }

    let footer;

    if (isUploading) {
        footer = (
            <button className="cancel-upload-button" onClick={() => api.cancelUpload(uploaderInstanceId)}>Cancel Upload</button>
        )
    } else if (failedUploads.length) {
        footer = (
            <div>
                <p className="upload-footer-failed-header">
                    All uploads did not complete successfully due to network errors.
                </p>
                <p>
                    Please retry.
                </p>
                <button className="next-prev-button" onClick={() => {
                    if (!uploaderInstanceId) {
                        throw new Error('Uploader instance id should always be set when retryFailedUploads button is present.');
                    }

                    api.retryFailedUploads(uploaderInstanceId, failedUploads)
                }}>Retry</button>
            </div>
        )
    } else {
        footer = (
            <div>
                <UploadStatusMessage
                    uploadInput={uploadInput}
                    fileCount={fileCount}
                    statusAndResults={statusAndResults}
                    studyMetadata={studyMetadata}
                    helpDeskEmailAddress={uploadInput.helpDeskEmailAddress}
                />
                <button className="next-prev-button" onClick={() => api.resetState()}>Upload more</button>
            </div>
        )
    }

    return (
        <div className="upload-status">
            {dicomStatusLines}
            {attachmentStatus()}
            {footer}
        </div>
    );
}


export default UploadStatus