import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
  useEffect,
  useMemo,
} from 'react';
import { getExtendedDicomFile } from '../lib/getExtendedDicomFile';
import { GetDicomHeaderReturnType, getDicomHeader } from '../lib/dicom';
import { generateUploadManifestAndFileMapFromFiles } from '../lib/uploadUtils';
import {
  CreateInstanceResponse,
  CreateInstanceBody,
  GuestUploaderSenderInfo,
  CreateGuestInstanceBody,
  UploadItem,
  StudyDateOverride
} from '../../../sharedTypes/bucketUploadTypes';
import {
  requestDICOMSignedUrls,
  requestAttachmentSignedUrls,
} from '../lib/api/signedUrls';
import { auth0Axios } from '../auth/auth0Axios';
import {
  UploadFileResult,
  useFileUploadQueue,
} from '../lib/hooks/useFileUploadQueue';
import { useOnUploadComplete } from '../lib/hooks/useOnUploadComplete';
import { ExtendedDicomFile, DicomFilesMap } from '../lib/types';
import {
  DeidentificationProtocol,
  DeidentifyConfig
} from '../../../sharedTypes/deidentifyTypes';
import {
  DicomTagOverridesPerStudy,
  UnionDeidentificationConfig,
  UserDefinedDeidentifyListPerStudy,
} from '../lib/deidentification/types';
import { combineDeidentifyProtocols } from '../lib/deidentification/combineDeidentifyProtocols';
import {
  OrderResponse,
  SitePatientInfo,
  SitePatientsForPatientResponse,
  TrialResponse,
} from '../../../sharedTypes/wmsQueryTypes';
import { getUploadLevel } from './utils/getUploadLevel';
import { getIsBlindedTrial } from './utils/getIsBlindedTrial';
import { getDeidentifyConfig } from './utils/getDeidentifyConfig';
import { clearCurrentUploaderInstanceId, isCurrentUploaderInstanceId, setCurrentUploaderInstanceId } from './utils/uploadCancelledState';

/**
 * How many URLs to sign at a time, value found as a comprise between making a large number
 * of calls and getting first upload starting quickly.
 */
const SIGNURL_BATCH_SIGN_COUNT = 10;

/**
 * These codes determine where we are in the uploader flow.
 */
enum StateCode {
  /**
   * Select the dicom data to be uploaded from a directory or bunch of files.
   */
  SELECT_DATA = 'SELECT_DATA',
  /**
   * Build out deidentification profiles and add attachments.
   */
  DEID_AND_ADD_ATTACHMENTS = 'DEID_AND_ADD_ATTACHMENTS',
  /**
   * Process and monitor the actual upload.
   */
  UPLOAD_STATUS = 'UPLOAD_STATUS',
}

type StudyMetadata = {
  StudyInstanceUID: string;
  fileCount: number;
  header: any;
};

enum UploadLevel {
  ORGANIZATION = 'ORGANIZATION',
  TRIAL = 'TRIAL',
  ORDER = 'ORDER',
}

type UploadInput = {
  helpDeskEmailAddress?: string;
  trial_patient_id?: string;
  trial_id?: string;
  order_id?: string;
  trial?: TrialResponse;
  patient?: SitePatientsForPatientResponse;
  order?: OrderResponse;
  guestUploader?: {
    guestUploaderId: string;
    temporary: boolean;
  };
  deidentifying: boolean;
  uploadLevel: UploadLevel;
  isBlindedTrial: boolean;
};

type SetUploadInputInterface = Omit<UploadInput, 'uploadLevel' | 'isBlindedTrial'>;

type UploadArgs = {
  files: DicomFilesMap;
  attachments: File[];
  uploadInput: UploadInput;
  selectedSiteId?: string;
  studyMetadata: StudyMetadata[];
  studyDateOverride?: StudyDateOverride;
  patientInformation?: SitePatientInfo;
  subjectId?: string;
  deidentification?: {
    mandatoryDeidentifyList?: DeidentificationProtocol;
    userDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
    dicomTagOverridesPerStudy: DicomTagOverridesPerStudy;
    redactBurnedInPHI: boolean;
  };
  guestSenderInfo?: GuestUploaderSenderInfo;
  rewriteUIDs: boolean;
};



type FileStateAPI = {
  resetState: () => void;
  addFiles: (oldFiles: DicomFilesMap, newFiles: File[]) => void;
  addAttachments: (newFiles: File[]) => void;
  removeAttachment: (fileName: string) => void;
  removeStudies: (StudyInstanceUIDs: string[], preventReset?: boolean) => void;
  selectStudies: () => void;
  upload: (uploadArgs: UploadArgs) => void;
  retryFailedUploads: (uploaderInstanceId: string, failedUploads: UploadFileResult[]) => void;
  cancelUpload: (uploaderInstanceId?: string) => void;
  setGuestSenderInfo: (guestSenderInfo: GuestUploaderSenderInfo) => void;
  setMandatoryDeidentifyList: (
    wmsDeidentificationProtocol: DeidentificationProtocol | undefined,
    guestDeidentificationProtocol?: DeidentificationProtocol
  ) => void;
  setMandatoryRedactBurnedInPHI: (value: boolean) => void;
  setManualRedactBurnedInPHI: (value: boolean) => void;
  mutateDicomTagOverridesPerStudy: (
    newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy
  ) => void;
  mutateUserDefinedDeidentifyList: (
    newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy
  ) => void;
  setUploadInput: (uploadInput: SetUploadInputInterface) => void;
  setSiteId: (siteId: string) => void
  setStudyDateOverride: (override: StudyDateOverride) => void;
  setRewriteUIDs: (shouldRewrite: boolean) => void;
};

type FileStateContextType = {
  uploadInput: UploadInput;
  siteId?: string;
  files: DicomFilesMap;
  guestUploaderInfo?: GuestUploaderSenderInfo;
  attachments: File[];
  guestSenderInfo?: GuestUploaderSenderInfo;
  failedUploads: UploadFileResult[];
  mandatoryDeidentifyList: UnionDeidentificationConfig | undefined; // A list of tags we need to deidentify as specified by all protocols.
  mandatoryRedactBurnedInPHI: boolean;
  disallowedDeidentifyTags: string[];
  manualRedactBurnedInPHI: boolean;
  userDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
  dicomTagOverridesPerStudy: DicomTagOverridesPerStudy;
  unsupportedFileNames: string[];
  studyMetadata: StudyMetadata[];
  studyDateOverride?: StudyDateOverride;
  status: {
    isParsingFiles: boolean;
    isUploading: boolean;
    fileGroupUploadCounts: Record<string, number>;
  };
  state: StateCode;
  api: FileStateAPI;
  uploaderInstanceId?: string;
  rewriteUIDs: boolean;
};

const getDefaultUploadInput = (): UploadInput => {
  return { deidentifying: false, uploadLevel: UploadLevel.ORGANIZATION, isBlindedTrial: false };
};

const getDefaultState = (): FileStateContextType => {
  return {
    files: {},
    uploadInput: getDefaultUploadInput(),
    attachments: [],
    failedUploads: [],
    mandatoryDeidentifyList: [],
    mandatoryRedactBurnedInPHI: false,
    disallowedDeidentifyTags: [],
    manualRedactBurnedInPHI: false,
    userDefinedDeidentifyListPerStudy: {},
    dicomTagOverridesPerStudy: {},
    unsupportedFileNames: [],
    studyMetadata: [],
    status: {
      isParsingFiles: false,
      isUploading: false,
      fileGroupUploadCounts: {},
    },
    state: StateCode.SELECT_DATA,
    rewriteUIDs: false,
    api: {
      addFiles: () => { },
      addAttachments: () => { },
      removeAttachment: () => { },
      selectStudies: () => { },
      resetState: () => { },
      removeStudies: () => { },
      upload: () => { },
      retryFailedUploads: () => { },
      cancelUpload: () => { },
      setGuestSenderInfo: () => { },
      setMandatoryDeidentifyList: () => { },
      mutateUserDefinedDeidentifyList: () => { },
      mutateDicomTagOverridesPerStudy: () => { },
      setMandatoryRedactBurnedInPHI: () => { },
      setManualRedactBurnedInPHI: () => { },
      setUploadInput: () => { },
      setSiteId: () => { },
      setStudyDateOverride: () => { },
      setRewriteUIDs: () => { }
    },
  };
};

const FileStateContext = createContext<FileStateContextType>(getDefaultState());

const useFileState = (): FileStateContextType => useContext(FileStateContext);

const getStudyMetadata = async (
  dicomFilesMap: DicomFilesMap
): Promise<StudyMetadata[]> => {
  const fileCountPerStudy: Record<string, number> = {};
  const headerPromises: Promise<GetDicomHeaderReturnType>[] = [];

  Object.keys(dicomFilesMap).forEach((StudyInstanceUID) => {
    const files = dicomFilesMap[StudyInstanceUID];

    fileCountPerStudy[StudyInstanceUID] = files.length;
    headerPromises.push(getDicomHeader(files[0]));
  });

  const headers = await Promise.all(headerPromises);

  const studyMetadata = headers.map(({ StudyInstanceUID, header }) => {
    return {
      StudyInstanceUID,
      header,
      fileCount: fileCountPerStudy[StudyInstanceUID],
    };
  });

  return studyMetadata;
};

const getDicomMrn = (studyMetadata: StudyMetadata[]): string => {
  const firstStudy = studyMetadata[0];
  const { header } = firstStudy;

  return header.PatientID;
};

/**
 * Provider that provides a store and API for file and metadata management.
 */
function FileStateProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<StateCode>(StateCode.SELECT_DATA);
  const [files, setFiles] = useState<DicomFilesMap>({});
  const [attachments, setAttachments] = useState<File[]>([]);
  const [unsupportedFileNames, setUnsupportedFileNames] = useState<string[]>(
    []
  );
  const [rewriteUIDs, setRewriteUIDs] = useState<boolean>(false);
  const [isParsingFiles, setIsParsingFiles] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [studyMetadata, setStudyMetadata] = useState<StudyMetadata[]>([]);
  const [studyDateOverride, setStudyDateOverride] = useState<StudyDateOverride | undefined>({});
  const [totalFileCount, setTotalFileCount] = useState<number>(0);
  const [disallowedDeidentifyTags, setDisallowedDeidentifyTags] = useState<
    string[]
  >([]);
  const [mandatoryDeidentifyList, setMandatoryDeidentifyList] = useState<
    UnionDeidentificationConfig | undefined
  >(undefined);
  const [mandatoryRedactBurnedInPHI, setMandatoryRedactBurnedInPHI] =
    useState<boolean>(false);
  const [manualRedactBurnedInPHI, setManualRedactBurnedInPHI] =
    useState<boolean>(true);
  const [
    userDefinedDeidentifyListPerStudy,
    setUserDefinedDeidentifyListPerStudy,
  ] = useState<Record<string, string[]>>({});
  const [dicomTagOverridesPerStudy, setDicomTagOverridesPerStudy] =
    useState<DicomTagOverridesPerStudy>({});
  const [uploaderInstanceId, setUploaderInstanceId] = useState<
    string | undefined
  >();
  const [guestSenderInfo, setGuestSenderInfo] = useState<
    GuestUploaderSenderInfo | undefined
  >(undefined);
  const [failedUploads, setFailedUploads] = useState<UploadFileResult[]>([]);
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [uploadInput, setUploadInput] = useState<UploadInput>(
    getDefaultUploadInput()
  );

  useEffect(() => {
    let totalFileCount = attachments.length;

    Object.keys(files).forEach((StudyInstanceUID) => {
      totalFileCount += files[StudyInstanceUID].length;
    });

    setTotalFileCount(totalFileCount);
  }, [attachments, files]);

  const {
    totalUploadCount,
    fileGroupUploadCounts,
    completedToNotify,
    uploadFileAndUpdateCount,
    cancelUpload
  } = useFileUploadQueue();

  useOnUploadComplete(
    uploaderInstanceId,
    completedToNotify,
    totalUploadCount,
    totalFileCount
  );

  const api: FileStateAPI = useMemo(
    () => ({
      resetState: () => {
        setState(StateCode.SELECT_DATA);
        setFiles({});
        setAttachments([]);
        setUnsupportedFileNames([]);
        setIsParsingFiles(false);
        setIsUploading(false);
        setStudyMetadata([]);
        setStudyDateOverride(undefined);
        setManualRedactBurnedInPHI(true);
        setUserDefinedDeidentifyListPerStudy({});
        setDicomTagOverridesPerStudy({});
        setGuestSenderInfo(undefined);
        setFailedUploads([]);
        cancelUpload();
        setRewriteUIDs(false);
      },
      setRewriteUIDs: (shouldRewrite: boolean) => {
        setRewriteUIDs(shouldRewrite)
      },
      setSiteId: (siteId: string) => {
        setSiteId(siteId);
      },
      setUploadInput: (uploadInput: SetUploadInputInterface) => {
        const uploadLevel = getUploadLevel(uploadInput);
        const isBlindedTrial = getIsBlindedTrial(uploadInput);

        setUploadInput({ ...uploadInput, uploadLevel, isBlindedTrial });
      },
      addFiles: async (oldFiles: DicomFilesMap, newFiles: File[]) => {
        setIsParsingFiles(true);

        // Can generate a list of studyInstanceUIDs also.
        // Make a map of files per study instance UIDs.
        // Read the headers of one of these files with DCMJS.

        const filePromises: Promise<ExtendedDicomFile>[] = [];

        newFiles.forEach((file) => {
          filePromises.push(getExtendedDicomFile(file));
        });

        const extendedFilesResults = await Promise.all(filePromises);

        // Filter out files which were not dicom/malformed.
        const newValidDicomFiles: DicomFilesMap = {};
        const unsupportedFileNames: string[] = [];

        extendedFilesResults.forEach((file) => {
          const { isDicom, StudyInstanceUID } = file;
          if (isDicom) {
            if (!newValidDicomFiles[StudyInstanceUID]) {
              newValidDicomFiles[StudyInstanceUID] = [];
            }
            newValidDicomFiles[StudyInstanceUID].push(file);
          } else {
            unsupportedFileNames.push(file.name);
          }
        });

        const newStudyMetadata = await getStudyMetadata(newValidDicomFiles);

        // TODO_JAMES:
        // Pull updated file calculation out.
        // Make The study file count just take the new length of files.

        const updatedFiles = { ...oldFiles };

        Object.keys(newValidDicomFiles).forEach(StudyInstanceUID => {
          const newFilesForStudy = newValidDicomFiles[StudyInstanceUID]

          if (files[StudyInstanceUID]) {
            const oldDicomStudy = files[StudyInstanceUID];
            const existingSOPInstanceUIDsForStudy = oldDicomStudy.map(extendedDicomFile => extendedDicomFile.SOPInstanceUID)

            // Study exists, append files. Make sure we don't double include files already included.
            newFilesForStudy.forEach(file => {
              if (!existingSOPInstanceUIDsForStudy.includes(file.SOPInstanceUID)) {
                updatedFiles[StudyInstanceUID].push(file)
              }
            })
          } else {
            // New study, add new entry.
            updatedFiles[StudyInstanceUID] = newFilesForStudy;
          }
        })

        setFiles(updatedFiles);

        setUnsupportedFileNames(unsupportedFileNames);
        setStudyMetadata((oldStudyMetadata) => {
          const updatedStudyMetadata = [...oldStudyMetadata];

          newStudyMetadata.forEach(study => {
            // We need to check if the newly added files are put of studies already added.

            const existingStudy = updatedStudyMetadata.find(sm => sm.StudyInstanceUID === study.StudyInstanceUID);

            if (existingStudy) {
              // Include the new files in the count.
              existingStudy.fileCount = updatedFiles[existingStudy.StudyInstanceUID].length
            } else {
              // Append the new study.
              updatedStudyMetadata.push(study);
            }
          });

          return updatedStudyMetadata;
        });
        setIsParsingFiles(false);
      },
      addAttachments: (newFiles: File[]) => {
        setAttachments((oldAttachments) => {
          const existingAttachmentNames = oldAttachments.map(
            (attachment) => attachment.name
          );

          // Remove any duplicates
          const actualNewFiles = newFiles.filter(
            (file) => !existingAttachmentNames.includes(file.name)
          );

          return [...oldAttachments, ...actualNewFiles];
        });
      },
      removeAttachment: (fileName: string) => {
        setAttachments((oldAttachments) => {
          const newAttachments = oldAttachments.filter(
            (attachment) => attachment.name !== fileName
          );

          return newAttachments;
        });
      },
      selectStudies: () => {
        setState(StateCode.DEID_AND_ADD_ATTACHMENTS);
      },
      removeStudies: (StudyInstanceUIDs: string[], preventReset = false) => {
        setStudyMetadata((oldStudyMetadata) => {
          const newStudyMetadata = oldStudyMetadata.filter(
            (study) => !StudyInstanceUIDs.includes(study.StudyInstanceUID)
          );

          if (newStudyMetadata.length === 0 && !preventReset) {
            // Removed all studies go back to select page.
            api.resetState();

            return [];
          }

          return newStudyMetadata;
        });

        setFiles((oldFiles) => {
          const remainingFiles = { ...oldFiles };

          StudyInstanceUIDs.forEach(
            (StudyInstanceUID) => delete remainingFiles[StudyInstanceUID]
          );

          return remainingFiles;
        });
      },
      setStudyDateOverride: (override: StudyDateOverride) => {
        setStudyDateOverride(override);
      },
      upload: async (uploadArgs: UploadArgs) => {
        setState(StateCode.UPLOAD_STATUS);
        setIsUploading(true);
        clearCurrentUploaderInstanceId();

        const {
          files,
          attachments,
          uploadInput,
          selectedSiteId,
          studyMetadata,
          studyDateOverride,
          patientInformation,
          guestSenderInfo,
          rewriteUIDs
        } = uploadArgs;

        let fileMap: Record<string, File>;
        let totalDicomFileCount: number = 0;

        Object.keys(files).forEach((StudyInstanceUID) => {
          totalDicomFileCount += files[StudyInstanceUID].length;
        });

        let uploaderInstanceId: string;

        const StudyInstanceUIDs = studyMetadata.map(
          (sm) => sm.StudyInstanceUID
        );

        let deidentifyConfig: DeidentifyConfig | undefined = getDeidentifyConfig(uploadArgs, studyMetadata)

        if (uploadInput.guestUploader) {
          const { guestUploaderId } = uploadInput.guestUploader;

          const generateUploadManifestResult =
            generateUploadManifestAndFileMapFromFiles(
              files,
              StudyInstanceUIDs,
              attachments
            );

          const manifest = generateUploadManifestResult.manifest;
          fileMap = generateUploadManifestResult.fileMap;

          const payload: CreateGuestInstanceBody = {
            manifest,
            senderInfo: guestSenderInfo,
            dicom_config: deidentifyConfig,
            study_date_override: studyDateOverride,
            patient: patientInformation,
            site_id: selectedSiteId,
            rewrite_uids: rewriteUIDs
          };

          const result: CreateInstanceResponse = await auth0Axios.post(
            `/api/uploader/guest-instance/${guestUploaderId}`,
            payload
          );

          uploaderInstanceId = result.data.uploaderInstanceId;
        } else {
          const { trial_patient_id, trial_id, order_id } = uploadInput;

          const generateUploadManifestResult =
            generateUploadManifestAndFileMapFromFiles(
              files,
              StudyInstanceUIDs,
              attachments
            );

          const manifest = generateUploadManifestResult.manifest;
          fileMap = generateUploadManifestResult.fileMap;

          const payload: CreateInstanceBody = {
            site_id: selectedSiteId,
            trial_patient_id,
            trial_id,
            order_id,
            patient: patientInformation,
            manifest,
            dicom_config: deidentifyConfig,
            study_date_override: studyDateOverride,
            rewrite_uids: rewriteUIDs
          };

          const result: CreateInstanceResponse = await auth0Axios.post(
            '/api/uploader/instance/',
            payload
          );

          uploaderInstanceId = result.data.uploaderInstanceId;
        }

        /**
         * This a reactive var for UI.
         */
        setUploaderInstanceId(uploaderInstanceId);

        /**
         * This is global state for access is in handlers.
         */
        setCurrentUploaderInstanceId(uploaderInstanceId);

        const promises: Promise<UploadFileResult>[] = [];
        const failedUploads: UploadFileResult[] = [];

        const addUploadsToQueue = (
          uploadItems: UploadItem[]
        ): Promise<UploadFileResult>[] => {
          const promises = [];

          for (let i = 0; i < uploadItems.length; i++) {
            const { resource, signedUrl } = uploadItems[i];
            const file = fileMap[resource];

            const promise = uploadFileAndUpdateCount(uploaderInstanceId, resource, file, signedUrl);

            promise.then((result) => {
              if (result.success === false && result.cancelled === false) {
                failedUploads.push(result);
              }
            });

            promises.push(promise);
          }

          return promises;
        };

        const steps = Math.floor(
          totalDicomFileCount / SIGNURL_BATCH_SIGN_COUNT
        );

        // Request DICOM signedURLs in batches and kick off uploads.
        for (let i = 0; i <= steps; i++) {
          if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
            break;
          }

          const min = i * SIGNURL_BATCH_SIGN_COUNT;
          let max: number;

          // If we have an exact multiple of 10 images, we hit an edge case.
          // If min === totalDicomFileCount, we have already uploaded all of our images (zero indexed).
          if (min === totalDicomFileCount) {
            break;
          }

          if (i === steps) {
            max = totalDicomFileCount - 1;
          } else {
            max = min + SIGNURL_BATCH_SIGN_COUNT - 1;
          }

          try {
            const dicomUploadItems = await requestDICOMSignedUrls(
              uploaderInstanceId,
              min,
              max
            );

            if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
              break;
            }

            const promisesForUploads = addUploadsToQueue(dicomUploadItems);

            promises.push(...promisesForUploads);
          } catch (e: any) {
            if (isCurrentUploaderInstanceId(uploaderInstanceId)) {
              // Geniuine error
              throw new Error(e.message)
            }
          }
        }

        if (isCurrentUploaderInstanceId(uploaderInstanceId)) {
          try {
            // Then make the next call for attachements.
            const attachmentUploadItems =
              await requestAttachmentSignedUrls(uploaderInstanceId);
            const promisesForAttachments = addUploadsToQueue(attachmentUploadItems);

            promises.push(...promisesForAttachments);
          } catch (e: any) {
            if (isCurrentUploaderInstanceId(uploaderInstanceId)) {
              // Geniuine error
              throw new Error(e.message)
            }
          }
        }

        if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
          setFailedUploads([]);
          setIsUploading(false);

          // Note we do not need to await the promises, they will all terminate due to
          // uploaderInstanceId checks.

          return;
        }

        await Promise.all(promises);

        setFailedUploads(failedUploads);
        setIsUploading(false);
      },
      retryFailedUploads: async (uploaderInstanceUid: string, oldFailedUploads: UploadFileResult[]) => {
        setIsUploading(true);

        const promises: Promise<UploadFileResult>[] = [];
        const newFailedUploads: UploadFileResult[] = [];

        for (let i = 0; i < oldFailedUploads.length; i++) {
          const { resource, signedUrl, file } = oldFailedUploads[i];
          const promise = uploadFileAndUpdateCount(uploaderInstanceUid, resource, file, signedUrl);

          promise.then((result) => {
            if (result.success === false) {
              newFailedUploads.push(result);
            }
          });

          promises.push(promise);
        }

        await Promise.all(promises);

        setFailedUploads(newFailedUploads);
        setIsUploading(false);
      },
      cancelUpload: (uploadInstanceUid?: string) => {
        cancelUpload(uploadInstanceUid);
        setUploaderInstanceId(undefined);
        setState(StateCode.DEID_AND_ADD_ATTACHMENTS);
      },
      setGuestSenderInfo: (guestSenderInfo: GuestUploaderSenderInfo) => {
        setGuestSenderInfo(guestSenderInfo);
      },
      setMandatoryDeidentifyList: (
        wmsDeidentificationProtocol: DeidentificationProtocol | undefined,
        guestDeidentificationProtocol?: DeidentificationProtocol
      ) => {
        if (wmsDeidentificationProtocol) {
          const { unionDeidentificationConfig, disallowedDeidentifyTags } =
            combineDeidentifyProtocols(
              wmsDeidentificationProtocol,
              guestDeidentificationProtocol
            );

          setMandatoryDeidentifyList(unionDeidentificationConfig);
          setDisallowedDeidentifyTags(disallowedDeidentifyTags);

          return;
        }

        setMandatoryDeidentifyList(undefined);
        setDisallowedDeidentifyTags([]);
      },
      mutateDicomTagOverridesPerStudy: (
        newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy
      ) => {
        setDicomTagOverridesPerStudy((oldDicomOverridesPerStudy) => {
          const state = { ...oldDicomOverridesPerStudy };

          // Itterate over diff for each study
          Object.keys(newDicomTagOverridesPerStudy).forEach(
            (StudyInstanceUID) => {
              if (state[StudyInstanceUID] === undefined) {
                state[StudyInstanceUID] = {};
              }

              const newTagsForStudy =
                newDicomTagOverridesPerStudy[StudyInstanceUID];

              // Iterate over each tag changed
              Object.keys(newTagsForStudy).forEach((dicomKeyword) => {
                state[StudyInstanceUID][dicomKeyword] =
                  newTagsForStudy[dicomKeyword];
              });
            }
          );

          return state;
        });
      },
      mutateUserDefinedDeidentifyList: (
        newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy
      ) => {
        setUserDefinedDeidentifyListPerStudy(
          (oldUserDefinedDeidentifyListPerStudy) => {
            const state = { ...oldUserDefinedDeidentifyListPerStudy };

            // Itterate over diff for each study
            Object.keys(newUserDefinedDeidentifyListPerStudy).forEach(
              (StudyInstanceUID) => {
                if (state[StudyInstanceUID] === undefined) {
                  state[StudyInstanceUID] = [];
                }

                const newTagsForStudy =
                  newUserDefinedDeidentifyListPerStudy[StudyInstanceUID];

                // Iterate over each tag changed
                Object.keys(newTagsForStudy).forEach((dicomKeyword) => {
                  state[StudyInstanceUID].push(dicomKeyword);
                });
              }
            );

            return state;
          }
        );
      },
      setMandatoryRedactBurnedInPHI: (value: boolean) => {
        setMandatoryRedactBurnedInPHI(value);
      },
      setManualRedactBurnedInPHI: (value: boolean) => {
        setManualRedactBurnedInPHI(value);
      },
    }),
    [cancelUpload, uploadFileAndUpdateCount]
  );

  return (
    <FileStateContext.Provider
      value={{
        siteId,
        uploadInput,
        files,
        attachments,
        failedUploads,
        mandatoryDeidentifyList,
        mandatoryRedactBurnedInPHI,
        disallowedDeidentifyTags,
        manualRedactBurnedInPHI,
        userDefinedDeidentifyListPerStudy,
        dicomTagOverridesPerStudy,
        unsupportedFileNames,
        guestSenderInfo,
        studyMetadata,
        studyDateOverride,
        status: {
          isParsingFiles,
          isUploading,
          fileGroupUploadCounts,
        },
        api,
        state,
        uploaderInstanceId,
        rewriteUIDs
      }}
    >
      {children}
    </FileStateContext.Provider>
  );
}

const withFileStateProvider = (component: FC) => {
  const Component = component;

  return function () {
    return (
      <FileStateProvider>
        <Component />
      </FileStateProvider>
    );
  };
};

export { withFileStateProvider, useFileState, StateCode, UploadLevel, getDicomMrn };
export type {
  FileStateContextType,
  DicomTagOverridesPerStudy,
  StudyDateOverride,
  StudyMetadata,
  UploadInput,
  SetUploadInputInterface,
  UploadArgs
};
